import { orderRefundTransform } from "@charge/common";

import request from "@/plugins/axios";
class OrderRefundApi {
  listURL = "/api/order/platform/order-refund/list";
  listEffectKey = `get${this.listURL}`;
  list = async (params) => {
    const result = await request({
      url: this.listURL,
      params: params,
    });
    return orderRefundTransform.enrichList(result);
  };

  refundOrderURL = "/api/order/platform/order-refund/refund";
  refundOrderEffectKey = `post${this.refundOrderURL}`;
  refundOrder = async (data) => {
    const result = await request({
      url: this.refundOrderURL,
      data: data,
      method: "post",
    });
    return result;
  };
}

export const orderRefundApi = new OrderRefundApi();
