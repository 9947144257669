<template>
  <el-dialog width="500px" :visible.sync="dialogVisible" title="退款" destroy-on-close>
    <template #default>
      <el-form ref="form" :model="formData" :rules="rules" label-width="0">
        <div v-if="visible" class="cg-pl-10">
          <div v-if="showAmount" class="cg-flex cg-items-center cg-mb-20">
            <div>
              充电订单{{ orderInfo.payTypeLabel }}: {{ orderInfo.orderAmountYuan }}元（可退{{
                orderInfo.canRefundAmount
              }}元）
            </div>
            <div>退款</div>
            <div class="cg-mx-12">
              <el-form-item class="!cg-mb-0" prop="amount">
                <base-input
                  v-model="formData.amount"
                  class="cg-w-100"
                  input-type="decimal"
                  placeholder="请输入金额"
                ></base-input>
              </el-form-item>
            </div>
            <div>元</div>
          </div>
          <div v-if="showMealCard" class="cg-mb-20">
            <el-checkbox
              v-model="formData.refundMealCard"
              label="充电订单套餐卡支付：1次"
            ></el-checkbox>
          </div>

          <div v-if="showSecureService" class="cg-mb-20">
            <el-checkbox
              v-model="formData.refundSecureService"
              label="安心充订单退款"
            ></el-checkbox>
          </div>
          <div class="cg-mb-8">退款原因</div>
          <el-input
            v-model="formData.description"
            type="textarea"
            placeholder="请输入内容"
            :autosize="{ minRows: 3 }"
            maxlength="99"
          />
        </div>
      </el-form>
    </template>
    <template #footer>
      <el-button @click="onClickCancel">取 消</el-button>
      <el-button :loading="refunding" type="primary" @click="onClickConfirm">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { yuanToCent } from "@charge/common";
import { mapState } from "vuex";

import { orderRefundApi } from "@/services/apis/order/order-refund";

const initFormData = {
  amount: "",
  refundSecureService: false,
  refundMealCard: false,
  description: "",
};
export default {
  name: "RefundModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orderInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      formData: {
        ...initFormData,
      },
    };
  },

  computed: {
    ...mapState("loading", ["effects"]),

    refunding() {
      return !!this.effects[orderRefundApi.refundOrderEffectKey];
    },

    showAmount() {
      return !this.orderInfo.isMealCardOrder && this.orderInfo.canRefundAmount > 0;
    },

    showMealCard() {
      return this.orderInfo.isMealCardOrder && !this.orderInfo.mealCardRefundStatusIsRefunded;
    },

    showSecureService() {
      return this.orderInfo.secureServiceStatusIsBought;
    },

    dialogVisible: {
      get() {
        return this.visible;
      },
      set() {
        this.onClickCancel();
      },
    },

    rules() {
      const amount = [
        {
          validator: (_, val, callback) => {
            const valNumber = parseFloat(val);
            if (valNumber > this.orderInfo.canRefundAmount) {
              callback(new Error("退款金额不能大于可退金额"));
            } else {
              callback();
            }
          },
        },
      ];
      if (!this.isMealCardOrder && !this.orderInfo.secureServiceStatusIsBought) {
        amount.unshift({ required: true, message: "请输入退款金额" });
      }
      return {
        amount: amount,
      };
    },
  },

  watch: {
    visible(val) {
      if (val) {
        this.formData = { ...initFormData };
      }
    },
  },

  methods: {
    onClickConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = {
            orderId: this.orderInfo.orderId,
            description: this.formData.description,
          };
          if (this.orderInfo.secureServiceStatusIsBought) {
            params.refundSecureService = this.formData.refundSecureService;
          }
          if (!this.orderInfo.isMealCardOrder) {
            params.amount = yuanToCent(this.formData.amount);
          } else {
            params.refundMealCard = this.formData.refundMealCard;
          }
          if (this.checkParams(params)) {
            await orderRefundApi.refundOrder(params);
            this.$emit("success");
            this.onClickCancel();
          } else {
            this.$message.error("请至少输入或勾选一项后重试");
          }
        }
      });
    },
    checkParams(params) {
      if (!this.orderInfo.isMealCardOrder) {
        if (!this.showAmount && this.showSecureService && !params.refundSecureService) {
          return false;
        }
        if (!params.amount && !params.refundSecureService) {
          return false;
        }
      } else {
        if (this.showMealCard && !this.showSecureService && !params.refundMealCard) {
          return false;
        }
        if (!this.showMealCard && this.showSecureService && !params.refundSecureService) {
          return false;
        }
        if (!params.refundMealCard && !params.refundSecureService) {
          return false;
        }
      }
      return true;
    },
    onClickCancel() {
      this.$emit("update:visible", false);
    },
  },
};
</script>
