<template>
  <el-dialog :visible.sync="dialogVisible" title="计费规则" width="1000">
    <template #default>
      <div v-loading="gettingChargeInfo" class="cg-px-24 cg-pb-24">
        <fee-rule-detail :price-detail="chargePlanInfo" :show-order-settings="showOrderSettings" />
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";

import { orderChargePlanApi } from "@/services/apis/order/charge-plan";

import FeeRuleDetail from "../FeeRuleDetail/index.vue";

export default {
  components: { FeeRuleDetail },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    priceDetail: {
      type: Object,
      default: () => ({}),
    },
    showOrderSettings: {
      type: Boolean,
      default: true,
    },
    orderId: {
      type: [Number, String],
      default: "",
    },
    maxPower: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      chargePlanInfo: {},
    };
  },

  computed: {
    ...mapState("loading", ["effects"]),

    gettingChargeInfo() {
      return !!this.effects[orderChargePlanApi.orderChargePlanEffectKey];
    },

    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    priceDetail: {
      immediate: true,
      handler(val) {
        if (val.chargeModel) {
          this.chargePlanInfo = val;
        }
      },
    },
    orderId: {
      immediate: true,
      async handler(val) {
        if (val) {
          const res = await orderChargePlanApi.orderChargePlan({ orderId: val });
          this.chargePlanInfo = {
            ...res,
            maxPower: this.maxPower,
          };
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
._title {
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}
</style>
