import { chargePlanTransform } from "@charge/common";

import request from "@/plugins/axios";

class OrderChargePlanApi {
  orderChargePlanURL = "/api/order/platform/order-charge-plan/order-charge-plan";
  orderChargePlanEffectKey = `get${this.orderChargePlanURL}`;
  orderChargePlan = async (params) => {
    const result = await request({
      url: this.orderChargePlanURL,
      params: params,
    });
    return chargePlanTransform.convertDetail(result);
  };
}

export const orderChargePlanApi = new OrderChargePlanApi();
