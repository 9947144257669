export const INSURE_STATUS = [
  { value: 1, label: "已购买" },
  { value: 2, label: "未购买" },
  { value: 3, label: "已退款" },
];

export const PAY_METHOD = [
  { value: 1, label: "余额支付" },
  { value: 2, label: "微信支付" },
  { value: 3, label: "月卡支付" },
  { value: 4, label: "实体卡支付" },
  { value: 5, label: "离线卡支付" },
  { value: 6, label: "套餐卡支付" },
];

export const ORDER_STATUS = [
  { value: 0, label: "待支付" },
  { value: 1, label: "充电中" },
  { value: 2, label: "已结束" },
  { value: 3, label: "已取消" },
  { value: 4, label: "下单失败" },
  { value: 5, label: "支付超时" },
  { value: 6, label: "已退款" },
];
