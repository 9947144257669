<template>
  <div>
    <el-descriptions title="定价信息" size="medium" :column="2" class="cg-mb-16">
      <el-descriptions-item label="所属商户">{{ priceDetail.companyName }}</el-descriptions-item>
      <el-descriptions-item label="定价名称">
        {{ priceDetail.chargePlanName }}
      </el-descriptions-item>
      <el-descriptions-item label="计费模式" span="2">
        {{ priceDetail.chargeModelLabel }}
      </el-descriptions-item>
      <template v-if="!priceDetail.isPower">
        <el-descriptions-item label="起步费用">
          {{ `${priceDetail.baseAmount}元/${priceDetail.baseMinutes}分钟` }}
        </el-descriptions-item>
        <el-descriptions-item label="加时费用">
          {{ `${priceDetail.overtimeAmount}元/${priceDetail.baseMinutes}分钟` }}
        </el-descriptions-item>
      </template>
      <el-descriptions-item v-else label="充电费用" :span="2">
        <power-table
          class="cg-w-full"
          :show-title="false"
          :data-source="priceDetail.powerChargeList"
          :max-power="priceDetail.maxPower"
        />
      </el-descriptions-item>

      <el-descriptions-item label="免费时长">
        {{ priceDetail.freeChargeDuration }}分钟
        <el-tooltip placement="top" content="订单结束时不满该时间，则不计算该订单费用">
          <i class="el-icon-warning-outline cg-text-primary cg-pl-12"></i>
        </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="单日免费次数">
        {{ priceDetail.freeChargeCount }}
        <el-tooltip placement="top" content="每个用户每天可参与免费时长的次数">
          <i class="el-icon-warning-outline cg-text-primary cg-pl-12"></i>
        </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="最大时长">
        {{ priceDetail.maxChargeDuration }}
        <el-tooltip placement="top" content="可以选择的最大充电时间">
          <i class="el-icon-warning-outline cg-text-primary cg-pl-12"></i>
        </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="定价描述">
        {{ priceDetail.chargePlanDescription }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="showOrderSettings" title="下单设置" size="medium" :column="2">
      <el-descriptions-item label="是否充满自停">
        {{ priceDetail.fullStopLabel }}
        <el-tooltip placement="top" content="作用于是否展示用户选择充满自停的选项">
          <i class="el-icon-warning-outline cg-text-primary cg-pl-12"></i>
        </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="候选顺序">
        {{ priceDetail.candidatesOrderLabel }}
        <el-tooltip placement="top" content="作用于用户选择充电方式的展示顺序">
          <i class="el-icon-warning-outline cg-text-primary cg-pl-12"></i>
        </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="候选方式">
        {{ priceDetail.candidatesTypeLabel }}
        <el-tooltip placement="top" content="作用于用户快捷选择充电方式的内容">
          <i class="el-icon-warning-outline cg-text-primary cg-pl-12"></i>
        </el-tooltip>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import PowerTable from "./PowerTable.vue";

export default {
  name: "FeeRuleDetail",
  components: { PowerTable },
  props: {
    priceDetail: {
      type: Object,
      default: () => ({}),
    },
    showOrderSettings: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    priceDetail: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
    },
  },
};
</script>
